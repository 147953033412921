/* .flag-select .country-flag */
@media only screen and (max-width: 767px){

  .flag-select .country-flag {
    cursor: pointer; 
    font-size: 18px;
  }

  .flag-select .filterBox input {
    width: 90%;
    margin: 0 4%; 
    font-size: 22px;
  }
  
}

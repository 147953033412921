/* .flag-select .country-flag */
@media only screen and (min-width: 768px) {

  .flag-select .country-flag {
    cursor: pointer; 
    font-size: 25px;
  }

  .flag-select .filterBox input {
    width: 90%;
    margin: 0 4%; 
    font-size: 20px;
  }
}
  